import * as THREE from 'three'
import { Canvas } from '@react-three/fiber'
import { Environment, Float, ContactShadows, OrbitControls, Stage, useGLTF, MeshTransmissionMaterial } from '@react-three/drei'
import { LayerMaterial, Color, Depth, Noise } from 'lamina'

export default function App() {
  const config = {
    backside: false,
    samples: 8,
    resolution: 128,
    transmission: 0.95,
    roughness: 0.5,
    clearcoat: 0.1,
    clearcoatRoughness: 0.1,
    thickness: 0.5,
    backsideThickness: 0,
    ior: 1.2,
    chromaticAberration: 1,
    anisotropy: 1,
    distortion: 0,
    distortionScale: 0.2,
    temporalDistortion: 0,
    attenuationDistance: 0.5,
    attenuationColor: '#ffffff',
    color: '#ffffff',
  }

  const { nodes, materials } = useGLTF('/doodle_mesh.gltf')
  return (
    <Canvas dpr={[1, 2]} camera={{ position: [0, 0, 5] }}>
      <OrbitControls />
      <Stage>
        <pointLight position={[10, 10, 5]} />
        <pointLight position={[-10, -10, -5]} />
        <ambientLight intensity={0.4} />
        {/* <Box args={[1, 1, 1]} position={[0, -1.5, 0]} castShadow receiveShadow>
        <LayerMaterial side={THREE.BackSide}>
          <Color color="white" alpha={1} mode="normal" />
          <Depth colorA="#eeffff" colorB="#ffffee" alpha={0.5} mode="normal" near={0} far={300} origin={[100, 100, 100]} />
          <Noise mapping="local" type="cell" scale={0.5} mode="softlight" />
        </LayerMaterial>
      </Box> */}
        <group position={[0, -1.5, 0]}>
          <Float position={[0, 2.15, 0]} speed={2} rotationIntensity={0} floatIntensity={2}>
            <mesh scale={5} renderOrder={100} geometry={nodes.mesh_0.geometry}>
              {/* <meshStandardMaterial color="white" roughness={0.1} metalness={0.925} /> */}

              <MeshTransmissionMaterial {...config} color="#aaaaaa" envMapIntensity={7} metalness={0.95} />
            </mesh>
          </Float>
          <ContactShadows scale={10} blur={3} opacity={0.25} far={10} />
        </group>

        {/* We're building a cube-mapped environment declaratively.
          Anything you put in here will be filmed (once) by a cubemap-camera
          and applied to the scenes environment, and optionally background. */}
        <Environment preset="sunset">
          <Striplight position={[10, 2, 0]} scale={[1, 3, 10]} />
          <Striplight position={[-10, 2, 0]} scale={[1, 3, 10]} />
          {/* <mesh scale={100}>
          <sphereGeometry args={[1, 64, 64]} />
          <LayerMaterial side={THREE.BackSide}>
            <Color color="white" alpha={1} mode="normal" />
            <Depth colorA="#eeffff" colorB="#ffffee" alpha={0.5} mode="normal" near={0} far={300} origin={[100, 100, 100]} />
            <Noise mapping="local" type="cell" scale={0.5} mode="softlight" />
          </LayerMaterial>
        </mesh> */}
        </Environment>
      </Stage>
    </Canvas>
  )
}

function Striplight(props) {
  return (
    <mesh {...props}>
      <boxGeometry />
      <meshBasicMaterial color="white" />
    </mesh>
  )
}
